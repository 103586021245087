<template>
  <div class="cta-block">
    <div class="icon">
      <div class="wrapper">
        <cms-components-shared-image
          fit-cover
          :image="icon"
          width="30"
          height="30"
          size="iconlarge"
        />
      </div>
    </div>
    <div class="content">
      <h3 class="title">
        {{ title }}
      </h3>
      <cms-components-rich-text
        class="description"
        :text="description"
      />
    </div>
    <div class="cta">
      <cms-components-shared-link
        :link="ctaLink"
        type="button"
        @link-click="handleCtaClick"
      >
        {{ ctaText }}
      </cms-components-shared-link>
    </div>
  </div>
</template>

<script>
import { trackCmsArticleCtaClick } from '@/helpers/conversion/conversion-cms'

export default {
  inject: {
    parentCategory: {
      from: 'parentCategory',
      default: {
        title: '',
      },
    },
    childCategory: {
      from: 'childCategory',
      default: {
        title: '',
      },
    },
  },
  props: {
    cta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    defaultCta() {
      return this.cta.cta
    },
    title() {
      if (this.cta.title) {
        return this.cta.title
      }

      return this.defaultCta.title
    },
    description() {
      if (this.cta.description) {
        return this.cta.description
      }

      return this.defaultCta.description
    },
    icon() {
      const icon = this.defaultCta.icon

      if (this.cta.icon) {
        icon.image = this.cta.icon
      }

      if (this.cta.iconAlternativeText) {
        icon.alternativeText = this.cta.iconAlternativeText
      }

      return icon
    },
    ctaLink() {
      return {
        link: this.link,
        title: this.ctaTitle,
        noIndex: this.noIndex,
        openInNewTab: this.openInNewTab,
      }
    },
    link() {
      if (this.cta.ctaLink) {
        return this.cta.ctaLink
      }

      return this.defaultCta.cta.link
    },
    ctaTitle() {
      if (this.cta.ctaTitle) {
        return this.cta.ctaTitle
      }

      return this.defaultCta.cta.title
    },
    ctaText() {
      if (this.cta.ctaText) {
        return this.cta.ctaText
      }

      return this.defaultCta.cta.text
    },
    noIndex() {
      if (this.cta.ctaNoIndex) {
        return this.cta.ctaNoIndex
      }

      return this.defaultCta.noIndex
    },
    openInNewTab() {
      if (this.cta.ctaOpenInNewTab) {
        return this.cta.ctaOpenInNewTab
      }

      return this.defaultCta.openInNewTab
    },
  },
  methods: {
    handleCtaClick() {
      trackCmsArticleCtaClick(this.title, this.parentCategory.title, this.childCategory?.title)
    },
  },
}
</script>

<style lang="less" scoped>
.cta-block {
  display: grid;
  grid-template-columns: 48px 1fr;

  background-color: var(--ds-color-primary-25);
  border-radius: 8px;
  padding: 28px 24px;

  .icon {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      overflow: hidden;
      height: 48px;
      width: 48px;

      border-radius: 24px;
      background-color: var(--ds-color-white-100);
    }
  }

  .content {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    padding-left: 18px;
  }

  .cta {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;

    padding-left: 18px;
  }

  .title {
    margin-top: 0;
    margin-bottom: 8px;

    font-weight: var(--ds-weight-semi-bold);
    font-size: 16px;
    color: var(--ds-color-gray-100);
  }

  .description {
    width: 100%;
    margin-bottom: 20px;

    font-weight: 300;
    font-size: 14px;
    color: var(--ds-color-gray-50);
  }

  @media @bp-desktop {
    .cta {
      grid-column-start: 2;
    }

    .title {
      font-size: 18px;
    }

    .description {
      font-size: 16px;
    }
  }
}
</style>
