<template>
  <div class="social-sharing">
    <div
      class="sharing-button"
      @click="handleSharing('twitter')"
    >
      <uikit-social-twitter-icon />
    </div>
    <div
      class="sharing-button"
      @click="handleSharing('facebook')"
    >
      <uikit-social-facebook-icon />
    </div>
    <div
      class="sharing-button"
      @click="handleSharing('linkedin')"
    >
      <uikit-social-linkedin-icon />
    </div>
    <div
      class="sharing-button"
      @click="handleSharing('share-native')"
    >
      <uikit-link-icon />
    </div>
  </div>
</template>

<script>
import {
  UikitSocialTwitterIcon,
  UikitSocialFacebookIcon,
  UikitSocialLinkedinIcon,
  UikitLinkIcon,
} from '@hz/ui-kit'

export default {
  components: {
    UikitSocialTwitterIcon,
    UikitSocialFacebookIcon,
    UikitSocialLinkedinIcon,
    UikitLinkIcon,
  },
  emits: ['share-content'],
  methods: {
    handleSharing(network) {
      this.$emit('share-content', network)
    },
  },
}
</script>

<style lang="less" scoped>
.social-sharing {
  display: flex;
  flex-direction: row;

  .sharing-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;

    &:not(:last-child) {
      margin-right: 24px;
      margin-bottom: 0;
    }

    background-color: var(--ds-color-primary-25);
    border: 1px solid var(--ds-color-gray-25);
    color: var(--ds-color-primary-100);
    border-radius: 20px;

    cursor: pointer;

    :deep(svg) {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
