<template>
  <article-card-generic class="author-card" no-shadow>
    <div class="picture-wrapper">
      <cms-components-shared-image
        fit-cover
        :image="author.picture"
        height="48"
        width="48"
        class="picture"
        size="iconlarge"
      />
    </div>
    <div class="content">
      <div class="name">{{ author.name }}</div>
      <div class="job">{{ author.job }}</div>
    </div>
  </article-card-generic>
</template>

<script>
export default {
  props: {
    author: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="less" scoped>
.author-card {
  flex-direction: row;
  padding: 18px 24px;
  background: var(--ds-color-gray-10);
  border-radius: 7px;

  .picture {
    border-radius: 24px;
  }

  .content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-left: 18px;
  }

  .name {
    font-weight: var(--ds-weight-semi-bold);
    font-size: var(--ds-space-lg);
    color: var(--ds-color-gray-100);
  }

  .job {
    font-weight: var(--ds-weight-regular);
    font-size: 14px;
    color: var(--ds-color-gray-50);
  }
}
</style>
