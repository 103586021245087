<template>
  <Section class="article-content" wide-mode small-space>
    <div class="side hide-on-mobile">
      <div class="sticky-content">
        <annonces random exclusive>
          <template #title>
            <h2>
              Annonces
              <span class="purple">exclusives</span>
              Horiz.io
            </h2>
          </template>
          <template #display-all>
            <NuxtLink
              class="link"
              :to="{
                name: 'biens-rentables-custom-search',
                query: { sorting: '-rendement', rendementMax: 17 },
              }"
            >
              Voir toutes les annonces »
            </NuxtLink>
          </template>
        </annonces>
        <article-toc :titles="titles" :activeTitle="activeTitle" />
        <article-social-sharing @share-content="handleSharing" />
      </div>
    </div>
    <div class="content-wrapper">
      <template v-for="part in formattedContent" :key="part.id">
        <div class="content-part">
          <article-content-text v-if="isRichText(part)" :text="part.text" />
          <article-cta-block v-else-if="isCtaBlock(part)" :cta="part" />
        </div>
      </template>
      <article-card-author :author="author" />
      <article-social-sharing class="hide-on-desktop" @share-content="handleSharing" />
      <annonces class="hide-on-desktop" column random exclusive>
        <template #title>
          <h2>
            Annonces
            <span class="purple">exclusives</span>
            Horiz.io
          </h2>
        </template>
        <template #display-all>
          <NuxtLink
            class="link"
            :to="{
              name: 'biens-rentables-custom-search',
              query: { sorting: '-rendement', rendementMax: 17 },
            }"
          >
            Voir toutes les annonces »
          </NuxtLink>
        </template>
      </annonces>
    </div>
  </Section>
</template>

<script>
import { useScreenSizeStore } from '@hz/ui-kit'
import { mapState } from 'pinia'

export default {
  props: {
    content: {
      type: Array,
      required: true,
    },
    author: {
      type: Object,
      required: true,
    },
  },
  emits: ['share-content'],
  data() {
    return {
      titles: [],
      formattedContent: [],
      activeTitle: '',
    }
  },
  computed: {
    ...mapState(useScreenSizeStore, ['isMobileWidth']),
  },
  watch: {
    content: {
      handler(content) {
        const titles = []
        const formattedContent = []

        content.forEach((part) => {
          if (this.isRichText(part)) {
            let formattedText = part.text
            const matched = part.text.match(/<(h2|h3)>(.*?)<\/(h2|h3)>/g)

            if (matched) {
              matched.forEach((title) => {
                const titleString = title.replace(/<\/?(h2|h3)>/g, '').replace(/&nbsp;/g, '')

                if ('' !== titleString) {
                  const anchor = titleString
                    .toLowerCase()
                    .replace(/[^\w ]+/g, '')
                    .replace(/ +/g, '-')
                  titles.push({
                    anchor,
                    title: titleString,
                    level: title.includes('h2') ? 1 : 2,
                  })
                  formattedText = formattedText.replace(
                    title,
                    `<a class="anchor" id="${anchor}"></a>${title}`,
                  )
                }
              })
            }

            const sizes = 'sizes="(max-width: 420px) 390px, (max-width: 1024px) 500px, 780px"'
            formattedText = formattedText.replaceAll('sizes="100vw"', sizes)

            // Replace local links by current domain
            formattedText = formattedText.replaceAll('https://horiz.io', this.$config.public.hpBaseUrl)

            formattedContent.push({
              ...part,
              text: formattedText,
            })
          } else {
            formattedContent.push(part)
          }
        })

        this.titles = titles
        this.formattedContent = formattedContent
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
    this.handleScroll()
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  },
  methods: {
    isRichText(item) {
      return 'shared.text' === item['__component']
    },
    isCtaBlock(item) {
      return 'global.cta-block' === item['__component']
    },
    handleScroll() {
      this.detectActiveTitle()
    },
    readingProgress() {
      const contentPaddingTop = 0 // Depend on device
      const headerHeight = this.isMobileWidth ? 110 : 80 // Depend on device

      const content = this.$el.querySelector('.content-wrapper')
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const contentTop = content.offsetTop + contentPaddingTop // Depend on top margin
      const contentBottom = contentTop + content.offsetHeight

      let progress = 0
      if (scrollTop < contentTop) {
        progress = 0
      } else if (scrollTop > contentBottom - (window.innerHeight - headerHeight)) {
        progress = 100
      } else {
        progress = Math.round(
          ((scrollTop - contentTop) / (content.offsetHeight - (window.innerHeight - headerHeight))) * 100,
        )
      }
      this.progress = progress
    },
    detectActiveTitle() {
      const titles = this.$el.querySelectorAll('.anchor')
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop

      const first = titles[0]
      const last = titles[titles.length - 1]

      if (!first || scrollTop < first.offsetTop) {
        this.setActiveTitle('')
      } else if (scrollTop > last.offsetTop) {
        this.setActiveTitle(last.id)
      } else {
        for (let i = 0; i < titles.length; i++) {
          const title = titles[i]

          const rect = title.getBoundingClientRect()
          const elemTop = rect.top
          const elemBottom = rect.bottom

          if (elemTop >= 0 && elemBottom <= window.innerHeight) {
            if (titles[i - 1]) {
              this.setActiveTitle(titles[i - 1].id)
            }
            break
          }
        }
      }
    },
    setActiveTitle(activeTitle) {
      // Hack to force reactivity render
      setTimeout(() => (this.activeTitle = activeTitle))
    },
    handleSharing(network) {
      this.$emit('share-content', network)
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.article-toc) {
  display: none;
}

.article-content {
  :deep(.section-container) {
    display: flex;
  }

  .sticky-content {
    position: sticky;
    top: 110px;
    display: flex;
    flex-direction: column;

    :deep(.social-sharing) {
      margin-left: -30px;
    }
  }

  :deep(.cta-block) {
    margin-bottom: 12px;
  }

  .content-wrapper {
    overflow: hidden;

    :deep(.author-card) {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    :deep(.social-sharing) {
      justify-content: center;
      margin-bottom: 32px;
    }
  }

  @media @bp-desktop {
    :deep(.section-container) {
      justify-content: space-between;
    }

    .side {
      max-width: 520px;
      min-width: 520px;
    }

    .sticky-content {
      position: sticky;
      top: 112px;

      > * {
        margin-bottom: 44px;
      }
    }

    :deep(.cta-block) {
      margin-bottom: 24px;
    }

    .content-wrapper {
      max-width: 774px;
      :deep(.author-card) {
        margin-bottom: 0;
      }
    }
  }
}

@media @bp-desktop {
  :deep(.article-toc) {
    display: block;
    padding-right: 32px;
  }
}
</style>
