<template>
  <cms-components-rich-text
    class="content-rich-text"
    :text="text"
  />
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="less" scoped>
.content-rich-text {
  :deep(.anchor) {
    display: block;
    position: relative;
    visibility: hidden;
    top: -110px;
  }

  // Reset
  :deep(p) {
    margin: 0;
  }

  :deep(figure) {
    margin: 0;
  }

  // Hack to detect H1 on article body
  :deep(h1) {
    background-color: hotpink;
    font-size: 150px;
  }

  :deep(h2) {
    font-weight: 400;
    font-size: 24px;
    color: var(--ds-color-primary-100);
  }

  :deep(h3) {
    font-weight: 400;
    font-size: 20px;
    color: var(--ds-color-primary-100);
    margin-top: 0;
  }

  :deep(p),
  :deep(ul),
  :deep(li),
  :deep(blockquote) {
    font-weight: 300;
    font-size: 14px;
    color: var(--ds-color-gray-50);
  }

  :deep(a:not(.anchor)) {
    font-weight: var(--ds-weight-semi-bold);
    font-size: 14px;
    color: var(--ds-color-primary-100);

    &:visited {
      color: var(--ds-color-primary-100);
    }
  }

  :deep(blockquote) {
    position: relative;
    margin: 0;
    padding: 12px 12px 12px 40px;

    background: var(--ds-color-white-100);
    box-shadow:
      0 0 3px rgba(0, 0, 0, 0.06),
      0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    p:not(:last-child) {
      margin-bottom: 6px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    &:before {
      color: var(--ds-color-primary-100);
      content: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='%236B4BCC' stroke='non' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.0001 2.63379C5.40008 2.63379 1.66675 6.36712 1.66675 10.9671C1.66675 15.5671 5.40008 19.3005 10.0001 19.3005C14.6001 19.3005 18.3334 15.5671 18.3334 10.9671C18.3334 6.36712 14.6001 2.63379 10.0001 2.63379ZM10.8334 15.1338H9.16675V10.1338H10.8334V15.1338ZM10.8334 8.46712H9.16675V6.80046H10.8334V8.46712Z' /%3E%3C/svg%3E%0A");
      position: absolute;
      left: 12px;
      top: 14px;
    }
  }

  :deep(p) > img {
    display: block;
    margin: 0 auto;
  }

  :deep(img) {
    width: 100%;
  }

  :deep(table) {
    width: 100%;
    border-spacing: 0;
    @table-border: 1px solid #e8eaed;
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    thead {
      tr th {
        background: var(--ds-color-gray-25);

        padding: 12px 24px;
        border-top: @table-border;
        border-bottom: @table-border;

        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: var(--ds-color-gray-50);

        &:first-child {
          border-top-left-radius: 8px;
          border-left: @table-border;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-right: @table-border;
        }
      }
    }

    tbody {
      tr td {
        padding: 12px 24px;

        background-color: var(--ds-color-white-100);

        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: var(--ds-color-gray-50);

        border-bottom: @table-border;

        &:first-child {
          border-left: @table-border;
        }

        &:last-child {
          border-right: @table-border;
        }
      }
    }
  }

  :deep(figure) figcaption {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: var(--ds-color-gray-50);
  }

  :deep(ul) {
    list-style: initial;
    padding-left: 20px;
  }

  // Content spacing
  :deep(h2),
  :deep(h3),
  :deep(p),
  :deep(ul),
  :deep(a:not(.anchor)),
  :deep(figure),
  :deep(blockquote) {
    margin-bottom: 12px;
  }

  :deep(li) {
    margin-bottom: 6px;
  }

  @media @bp-desktop {
    :deep(.anchor) {
      top: -80px;
    }

    :deep(h2) {
      font-size: 32px;
    }

    :deep(h3) {
      font-size: 28px;
    }

    :deep(p),
    :deep(ul),
    :deep(li),
    :deep(blockquote) {
      font-size: 16px;
    }

    :deep(a:not(.anchor)) {
      font-size: 16px;
    }

    // Content spacing
    :deep(h2),
    :deep(h3),
    :deep(p),
    :deep(ul),
    :deep(a:not(.anchor)),
    :deep(figure),
    :deep(blockquote) {
      margin-bottom: 24px;
    }

    :deep(li) {
      margin-bottom: 12px;
    }
  }
}
</style>
